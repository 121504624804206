export default class Menu {
    navResizeEvent = jQuery.Event('sidebarResize');

    isNavToggled = false;
    navHoverTimer;
    
    navContainer = document.querySelector('.sidebar');
    toggleButton = this.navContainer && this.navContainer.querySelector('#sidebarToggle, #sidebarToggleTop');


    constructor() {
        if (this.toggleButton) {

            this.menuToggleInit();
            // this.bindToggleButton();

            this.bindNavItemMouseOver();
            // this.bindNavItemMouseLeave(); // temporary 'fix'

            this.bindNavResize();
            this.bindFixHovered();

            this.bindScroll();
            this.bindScrollToTop();
        }
    }

    navClose = () => {
        $(this.navContainer).find('.collapse').each(function( index ) {
            $(this).collapse('hide');
        });
    }

    menuToggleInit = () => {
        const cookies = decodeURIComponent(document.cookie).split(';');
        cookies.forEach(cookie => {
            const data = cookie.split('=');
            if (data[0] === 'isNavToggled') {
                this.isNavToggled = data[1] === "false" ? false : true;
            }
        });
    }

    bindNavItemMouseOver() {
        $(this.navContainer).find('.nav-item').mouseover(function(){
            clearTimeout(this.navHoverTimer);
            $(this).find('.collapse').collapse('show');
         });
    }

    bindNavItemMouseLeave() {
        const that = this;

        $(this.navContainer).find('.nav-item').mouseleave(function(){
            that.navHoverTimer = setTimeout(that.navClose, 350);
        });
    }

    // bindToggleButton() {
    //     $("#sidebarToggle, #sidebarToggleTop").on('click', function(e) {
    //         $("body").toggleClass("sidebar-toggled");
    //         $(".sidebar").toggleClass("toggled");
    //         this.isNavToggled = !this.isNavToggled;
        
    //         if ($(".sidebar").hasClass("toggled")) {
    //             $('.sidebar .collapse').collapse('hide');
    //         };
        
    //         if( Boolean($.fn.dataTable) ) $(document).trigger('sidebarResize');
    //     });
    // }

    bindNavResize() {
        $(window).resize(function() {
            if( $(window).width() < 768 ) $('.sidebar .collapse').collapse('hide');
            if( Boolean($.fn.dataTable) ) $($.fn.dataTable.tables(true)).DataTable().columns.adjust();
        });
    }
    bindFixHovered() {
        $('body.fixed-nav .sidebar').on('mousewheel DOMMouseScroll wheel', function(e) {
            if ($(window).width() > 768) {
                var e0 = e.originalEvent,
                delta = e0.wheelDelta || -e0.detail;
                this.scrollTop += (delta < 0 ? 1 : -1) * 30;
                e.preventDefault();
            }
        });
    }

    bindScroll() {
        $(document).on('scroll', function() {
            var scrollDistance = $(this).scrollTop();
            if (scrollDistance > 100) $('.scroll-to-top').fadeIn();
            else $('.scroll-to-top').fadeOut();
        });
    }
    bindScrollToTop() {
        $(document).on('click', 'a.scroll-to-top', function(e) {
            var $anchor = $(this);
            $('html, body').stop().animate({
                scrollTop: ($($anchor.attr('href')).offset().top)
            }, 1000, 'easeInOutExpo');
            e.preventDefault();
        });
    }
}